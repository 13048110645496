<template>
  <div class="form-wrapper">
    <div class="design-form-box-echo">
      <div class="design-item" v-for="(item, index) in formsData" :key="index">
        <h1 class="flow-name" :style="{ color: basicColor[index] }">{{ basic[index] || '自定义流程名称' }}</h1>
        <div class="defalut"></div>
        <draggable
          class="design-form"
          :list="item.detail"
          item-key="id"
          :group="{ name: 'form', pull: '' }"
          chosen-class="choose"
          :animation="300"
          :sort="true"
          :disabled="true"
        >
          <template #item="{ element }">
            <div
              :class="['form-item-echo', { 'current-form': selectFormItem?.selectId === element.selectId }]"
              @click="selectitem(element, item)"
              :style="{
                border:
                  selectFormItem?.selectId === element.selectId ? '1px dashed #c3161c' : '1px dashed rgba(0,0,0,0)'
              }"
            >
              <FormDesignRender :config="{ ...element, allDisabled: true }" />
            </div>
          </template>
        </draggable>
      </div>
    </div>

    <div class="work-form-box">
      <div class="empty-config" v-if="!selectFormItem || formsData?.length === 0">
        <img src="@/assets/images/form-config-empty.png" alt="" srcset="" />
        <h2>选中控件后在这里进行查看</h2>
      </div>
      <div v-else>
        <ComponentName :name="selectFormItem.controlType" />
        <formConfigRender :allDisabled="true" />
      </div>
    </div>
  </div>
</template>

<script setup>
import FormDesignRender from '@/views/formDesign/commonViews/formDesignRender.vue'
import ComponentName from '@/views/formDesign/commonViews/componentName.vue'
import formConfigRender from '@/views/formDesign/commonViews/formConfigRender.vue'
import { computed, defineProps, reactive } from 'vue'
import draggable from 'vuedraggable'
import { useStore } from 'vuex'
const store = useStore()
const formsData = computed(() => store.state.process.logFormItems)
const basic = reactive(['修改前', '修改后'])
const basicColor = reactive(['#969799', '#131314'])
// 选中的控件信息
const selectFormItem = computed({
  get() {
    return store.state.process.selectFormItem
  },
  set(val) {
    store.state.process.selectFormItem = val
  }
})

defineProps({
  params: {
    type: Object,
    defalut: () => {
      return {
        formData: []
      }
    }
  }
})

// 选中控件
const selectitem = (element, item) => {
  selectFormItem.value = null
  if (element.type === 'REMOTE') {
    const options = ['常规盖印', '连续盖印', '远程盖印']
    element.props.options = options.map((item, index) => {
      const optionCopy = element.props.options[index]
      return {
        name: optionCopy?.name ? optionCopy?.name : optionCopy ? optionCopy : item,
        isOpen: optionCopy?.name ? optionCopy.isOpen : optionCopy !== null ? true : false,
        childSelect: optionCopy?.childSelect ? optionCopy.childSelect : [1, 2] //范围用印&录制视频
      }
    })
  }
  element.props.allDisabled = true

  store.commit('setSelectFormItems', item.detail)
  store.commit('setSelectFormItem', element)
  store.commit('mergeBasicInfo', item.basic)
  selectFormItem.value = element
}
</script>

<style lang="less" scoped>
@import url(./index.less);
</style>
